import { useEffect, useState } from "react";

export default function GridLocation({ location, isSelected, onSelect, game }) {

    const [selected, setSelected] = useState(isSelected);

    function hundleSelect(){
        setSelected(!selected);
        if(onSelect) onSelect(location);
    }

    return <button type="button"
        className={`btn ${selected ? 'btn-primary' : 'btn-light'} w-100 d-flex justify-content-center`}
        // style={{ fontSize: '0.1em' }}
        title={location}
        disabled={game != undefined}
        onClick={hundleSelect}
    >{(location+1).toString().padStart(4, '0')}</button>
}