import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useApi from "../../../hooks/api.hook";
//import ApiClient from "../../../services/api-client.service";

export default function Uploaded() {
  const [game, setGame] = useState();
  const location = useLocation();
  const pathParams = useParams();
  const api = new useApi(true);
  //const apiClient = new ApiClient();

  useEffect(() => {
    api.get(`/image/current_user/game/${pathParams.id}`).then((response) => {
      if (!response.error) {
        console.log(response.data);

        const game = {
          ...response.data,
          game_logs: response.data.game_logs.map((l) => ({
            ...l,
            data: JSON.parse(l.data),
          })),
        };

        // Manipulate the result to group by 'event' and include count
        if (game && game.game_logs) {
          const groupedLogs = {};
          game.game_logs.forEach((log) => {
            const eventName = log.event; // replace with the actual property name
            if (!groupedLogs[eventName]) {
              groupedLogs[eventName] = {
                logs: [],
                count: 0,
                eventName: eventName, // Include the event name
              };
            }
            groupedLogs[eventName].logs.push(log);
            groupedLogs[eventName].count++;
          });

          // Replace the original logs with the grouped logs
          game.game_logs = Object.values(groupedLogs);

          // Use the callback form of setGame to ensure you work with the updated state
          setGame((prevGame) => {
            console.log({ ...prevGame, ...game });
            return { ...prevGame, ...game };
          });
        }

        /*setGame({
          ...response.data,
          game_logs: response.data.game_logs.map((l) => ({
            ...l,
            data: JSON.parse(l.data),
          })),
        });

          // Manipulate the result to group by 'event' and include count
          if (game && game.game_logs) {
            const groupedLogs = {};
            game.game_logs.forEach(log => {
              const eventName = log.event; // replace with the actual property name
              if (!groupedLogs[eventName]) {
                groupedLogs[eventName] = {
                  logs: [],
                  count: 0,
                  eventName: eventName, // Include the event name
                };
              }
              groupedLogs[eventName].logs.push(log);
              groupedLogs[eventName].count++;
            });

            // Replace the original logs with the grouped logs
            game.game_logs = Object.values(groupedLogs);
            console.log(game);
          }*/
      }
    });
  }, [pathParams]);

  console.log(game);
  return (
    <>
      <h3>Game Info</h3>
      <hr />

      <div className="d-md-flex gap-3 flex-wrap">
        {!game && <div>Loading...</div>}

        {game && (
          <>
            <div>
              <img
                src={`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/static/${game.image}`}
              />

              <h6 className="my-3">Assigned Slots</h6>
              {game.game_instances ? (
                <div className="d-flex gap-1 mb-3">
                  {game.game_instances.map((gi) => (
                    <button className="btn btn-xs btn-light" key={gi.id}>
                      {gi.grid_location}
                    </button>
                  ))}
                </div>
              ) : (
                <span>Not assigned any slots yet</span>
              )}
            </div>

            <div
              className="flex-fill"
              style={{
                maxHeight: "400px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <h3>User Interactions</h3>

              {game.game_logs.length > 0 ? (
                <div>
                  {game.game_logs.map((log) => (
                    <div key={log.eventName}>
                      <span className="badge bg-secondary me-2">
                        {/*(new Date(log.createdAt)).toLocaleString()*/}
                        {log.eventName}
                      </span>
                      <span>
                        {/*log.data.type
                          ? `${//log.event} when playing`
                         : log.event*/}
                        {log.count}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <span>No events around this game</span>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
