import { createContext, useContext, useEffect, useReducer, useState } from "react";
import Auth from "../models/auth";
import { AuthContext } from "./auth.context";
import { useNavigate } from "react-router-dom";

export const UserContext = createContext([Auth.payload]);

export default function UserProvider({children}){
    const [auth] = useContext(AuthContext);
    const [user, setUser] = useState();
    const navigate = useNavigate();

    useEffect(()=>{
        if(auth && auth.payload){
            if(!auth.payload.verified){
                navigate('/auth/verify_email');
            }
            setUser(auth.payload);
        }
        else{
            setUser(undefined);
        }
    }, [auth]);

    return(<UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>);
}