export default function TermsOfUse() {
  return (
    <section className="container pt-4">
      <div>
        <h1>Terms of Use</h1>
        <p>
          Welcome to Play2o! These terms outline the rules and regulations for
          the use of Play2o's website. <br />
          By accessing this website, we assume you accept these terms and
          conditions. Do not continue to use <br />
          Play2o if you do not agree to take all of the terms and conditions
          stated on this page.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing and using the Play2o website, you agree to comply with
          and be bound by these terms <br />
          and conditions and all applicable laws, and regulations, and agree
          that you are responsible for compliance <br />
          with any applicable local laws. These terms apply to all visitors,
          users, and others who access or use <br />
          the service.
        </p>

        <h2>2. User Account</h2>
        <p>
          When you create an account with Play2o, you must provide accurate and
          complete information. <br />
          You are solely responsible for maintaining the confidentiality of your
          account and password. <br />
          Any unauthorized use of your account must be reported immediately.
        </p>

        <h2>3. Content Ownership</h2>
        <p>
          Any content you submit or contribute to Play2o remains your
          intellectual property. By submitting <br />
          content, you grant Play2o a non-exclusive, royalty-free license to
          use, modify, and distribute the content.
        </p>

        <h2>4. Submitted Content</h2>
        <p>
          Any content you submited will be subject to approval by Play2o. Play2o
          decision to approve or reject will be final.
          <br />
          You are prohibited from submitting inappropriate content.
        </p>

        <h2>4. Prohibited Activities</h2>
        <p>
          You agree not to engage in any activities that may harm Play2o, other
          users, or violate any laws. <br />
          Prohibited activities include unauthorized access, spamming,
          infringement, inappropriate content or any action that disrupts <br />
          the functionality of the platform.
        </p>

        <h2>5. Termination of Account</h2>
        <p>
          Play2o reserves the right to suspend or terminate your account if you
          violate these terms or engage
          <br /> in activities that are harmful to other users or the platform.
          Termination may result in the loss of data,
          <br />
          content, and access to the service.
        </p>

        <h2>6. Third-Party Links</h2>
        <p>
          Play2o website may contain links to third-party websites. These links
          are provided for your convenience, <br />
          and Play2o is not responsible for the content or privacy practices of
          these external sites. Users should
          <br />
          review the terms and privacy policies of any third-party websites.
        </p>

        <h2>7. Limitation of Liability</h2>
        <p>
          Play2o and its affiliates shall not be liable for any indirect,
          incidental, special, or consequential damages
          <br /> arising out of or in any way connected with the use of the
          platform. This includes but is not limited to <br />
          loss of revenue, data, or profits.
        </p>

        <h2>8. Governing Law</h2>
        <p>
          These terms are governed by and construed in accordance with the laws
          of Kenya, and any disputes <br />
          relating to these terms will be subject to the exclusive jurisdiction
          of the courts in Kenya.
        </p>

        <h2>9. Changes to Terms</h2>
        <p>
          Play2o reserves the right to update, change, or replace any part of
          these Terms of Use by posting <br />
          updates and changes to the website. Users are responsible for checking
          the website periodically for changes.
        </p>

        <h2>10. User Earnings</h2>
        <p>
          Play2o user earnings will be forwarded after confirmation by Play2o
          team and/or system records on the user
          <br /> who completed the token generation. Any contention will be
          decided on by Play2o and the decision will be final. <br />A user must
          have a paypal email to receive earnings. Earnings will be credited
          once the user accumulates <br />
          atleast $30. Any applicable transaction/ transmission fees will be
          deducted from the earnings.
        </p>

        <h2>11. Contact Us</h2>
        <p>
          If you have any questions about these Terms of Use, please contact us
          at support@play2o.com.
        </p>
      </div>
    </section>
  );
}
