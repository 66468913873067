export function numberToString(number){
    if(number >= 1000000){
        return `${Math.floor(number / 1000000)}M`;
    }
    else if(number >= 1000){
        return `${Math.floor(number / 1000)}K`;
    }
    
    return number
}

export function cropImage(url, toWidth, toHeight, x, y, width, height){
    return new Promise((rs, rj)=>{
        const canvas = document.createElement('canvas');
        canvas.width=toWidth;
        canvas.height=toHeight;
        const context = canvas.getContext("2d");

        const bigImage = new Image();
        bigImage.width = `${width}px`;
        bigImage.height = `${height}px`;
        bigImage.onload = ()=>{
            console.log('loaded');
            context.drawImage(bigImage, -x, -y, width, height );
            canvas.toBlob(blob=>rs(URL.createObjectURL( blob )));
        }
        bigImage.src = url;
    });
}

export function randomString(len){
    let alphabet = Array.from(Array(26), (_, i)=>String.fromCharCode(i + 97));
    alphabet = Array.from(Array(10), (_, i)=>i+1).concat(alphabet, alphabet.map(l=>l.toUpperCase()));
    // console.log(alphabet)
    return Array.from(Array(len)).map(()=>{
        return alphabet[Math.floor(Math.random() * alphabet.length)];
    }).join('');
}