export default function PrivacyPolicy() {
  return (
    <section className="container pt-4">
      <div>
        <h1>Privacy Policy</h1>
        <p>
          At Play2o, we respect your privacy and are committed to protecting it.
          This Privacy Policy explains how we collect,<br/> use, disclose, and
          safeguard your information when you use our services.
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          Play2o collects information that users provide directly, such as
          personal details, contact information, and other <br/>data necessary for
          the platform's services. The collection of information is essential
          for providing and improving services.
        </p>

        <h2>2. Use of Information</h2>
        <p>
          We may use your information to provide and improve our services,
          personalize your experience, and communicate <br/>with you.
          Collected information may be used to provide and improve services,
          personalize user experiences, <br/>and communicate with users. Play2o ensures
           that the information is used in compliance with privacy laws.
        </p>

        <h2>3. Data Security</h2>
        <p>
          Play2o implements security measures to protect user information from
           access, alteration, disclosure, or destruction. <br/>The platform uses
           encryption where applicable, secure socket layer technology, and
           may also undertake regular security audits.
        </p>

        <h2>4. Cookies</h2>
        <p>
          Play2o uses cookies to enhance user experiences on the platform.
          are small files placed on a user's device that <br/>improve website
          functionality. Users can choose to disable cookies through their browser settings.
        </p>

        <h2>5. Children's Privacy</h2>
        <p>
          Play2o's services are not intended for individuals under the age of 13.
          The platform does not knowingly <br/>collect personal information from
          children under 13 years old. Parents or guardians should contact
          Play2o <br/>if they believe personal information of a child has been collected.
          Creating an account with false info is <br/> a violation of our terms. This includes
           accounts registered on the behalf of someone under 13.
        </p>

        <h2>6. Information Sharing</h2>
        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties. This does not include <br/>trusted third parties who
          assist us in operating our website or providing services, as long as
          they agree to keep<br/> this information confidential.
        </p>

        <h2>7. Changes to Privacy Policy</h2>
        <p>
          Play2o reserves the right to update or change the Privacy Policy at any
           time. Changes will be posted on the <br/>website, and the effective date will
            be updated. Users are encouraged to review the Privacy Policy periodically.
        </p>

        <h2>8. Your Consent</h2>
        <p>
          By using Play2o's services, users consent to the terms outlined in
          the Privacy Policy. Users have the right to <br/>withdraw consent or
          request the deletion of their data.
        </p>

        <h2>9. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at privacy@play2o.com.
        </p>
      </div>
    </section>
  );
}
