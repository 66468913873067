import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './context/worker/worker'  "start": "PORT=3011 react-scripts start",

window.socketWorker = new Worker(new URL("./context/worker/worker.ts", import.meta.url), { type: "module" });
window.gridRowSize = window.location.host.includes("localhost") ? 1000 : 10000;
window.maxImageSize = 300;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
