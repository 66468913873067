import { Link, useLocation, useParams } from "react-router-dom";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import GameFilter from "../../components/game-filter/GameFilter";
import Game from "./Game";
import "./games.css";
import { UserContext } from "../../context/user.context";
import useApi from "../../hooks/api.hook";
import { DisplayContext } from "../../context/display.context";
import { WorkerContext } from "../../context/worker/worker.context";
import useWorkerMessageChannel from "../../hooks/useWorkerMessageChannel.hook";
import PaymentDetailsForm from "../../components/payments-details-form/PaymentDetailsForm";
import { Modal } from "react-bootstrap";
import gamesReducer from "../../reducers/games.reducer";
//import ApiClient from "../../services/api-client.service";

export default function Games() {
  const gridRowSize = window.gridRowSize;
  const apiClient = useApi(true);
  const pathParams = useParams();
  const { state } = useLocation();
  const category = state ? state.category : null;
  // const [games, setGames] = useState([]);
  const [games, gameDispatch] = useReducer(
    gamesReducer,
    Array(gridRowSize).fill(undefined)
  );
  const [selectedGame, setSelectedGame] = useState(null);
  const gameModal = useRef();
  const [rewardInfo, setRewardInfo] = useState();
  const [user] = useContext(UserContext);
  const [bockedSlot, setBookedSlot] = useState();
  const [players, setPlayers] = useState([]);
  const devicdeWidth = useContext(DisplayContext);
  const { worker, workerMessage } = useContext(WorkerContext);
  const [btnWidth, setBtnWidth] = useState(30);
  const [gameIsClosedModal, setGameIsClosedModal] = useState(false);
  //const api = new ApiClient();

  useEffect(() => {
    apiClient.get("/game_instances?status=accepted").then((response) => {
      if (response && !response.error) {
        console.log(response.data);
        response.data.forEach((slot) => {
          const action =
            !category ||
            category.parentCategoryID == slot.game_image.parentCategoryID
              ? {
                  type: "updateByGridLocation",
                  game: {
                    ...slot.game_image,
                    status: slot.status,
                    grid_location: slot.grid_location,
                  },
                }
              : {
                  type: "removeFromSlot",
                  game: { grid_location: slot.grid_location },
                };

          gameDispatch(action);
        });
        setBookedSlot(response.data);
      }
    });

    if (workerMessage) {
      switch (workerMessage.as) {
        case "game-room":
          if (workerMessage && workerMessage.as && workerMessage.players) {
            setPlayers(workerMessage.players);
          }
          break;
        case "game-play":
          if (workerMessage.type === "complete") {
            if (selectedGame) {
              setSelectedGame((prev) => ({
                ...prev,
                status: workerMessage.type,
              }));
              gameDispatch({
                type: "updateByGridLocation",
                game: {
                  ...selectedGame,
                  status: "complete",
                  // grid_location: workerMessage.gameSlot
                },
              });
            }
          }
          break;
        case "win":
          const wins = JSON.parse(
            atob(localStorage.getItem("wins") || btoa("[]"))
          );
          const win = wins.find((w) => w.code === workerMessage.code);
          if (win) {
            const pd = { ...workerMessage, paypalEmail: "" };
            delete pd.as;
            gameModal.current.close();
            setRewardInfo(pd);
          }
          break;
      }
    }
  }, [pathParams, workerMessage, category]);

  function handleSlotHover(data, i, game) {
    if (worker && game != null) {
      worker.postMessage({
        action: "send",
        as: "event",
        type: "hover",
        data,
      });
    }
  }

  function handleSlotClick(data, i, game) {
    worker.postMessage({
      action: "send",
      as: "event",
      type: "click",
      data,
    });
  }

  function handleOpenGame(g, i, game) {
    //if (game !=  null)
    handleSlotClick({ slot: i, game: game.gameID }, i);

    if (g.status === "complete") {
      setGameIsClosedModal(true);
      return;
    }

    setSelectedGame(g);
    if (g.status === "pending") {
      worker.postMessage({
        action: "send",
        as: "game-room",
        payload: {
          gameSlot: g.slot,
          state: "join",
          date: new Date().getTime(),
        },
      });
    }
    gameModal.current.showModal();
  }

  function handleCloseGame() {
    gameModal.current.close();
    if ((selectedGame.status = "pending")) {
      worker.postMessage({
        action: "send",
        as: "game-room",
        payload: {
          gameSlot: selectedGame.slot,
          state: "left",
          date: new Date().getTime(),
        },
      });
    }
    setSelectedGame(undefined);
  }

  return (
    <>
      <GameFilter category={category}>
        {user && (
          <div className="me-md-4 me-sm-2">
            <Link
              to={"/uploadgame"}
              state={{
                category,
                bockedSlot: (bockedSlot || []).map((s) => s.grid_location),
              }}
              className="btn btn-light"
            >
              <span className="d-none d-md-block">Upload Game</span>
              <span className="d-md-none">&#8683;</span>
            </Link>
          </div>
        )}
        <div className="ms-auto d-flex">
          <div
            className={`rounded border border-success me-2 text-nowrap ${
              devicdeWidth < 480 ? " d-flex flex-column" : ""
            }`}
          >
            <span className="p-1 ps-2 pe-2 bg-success d-inline-block">
              &nbsp;
            </span>
            <span className="ps-2 pe-2 text-nowrap">
              Open<span className="d-none d-md-inline-block"> games</span>
            </span>
          </div>
          <div
            className={`rounded border border-success me-2 text-nowrap ${
              devicdeWidth < 480 ? " d-flex flex-column" : ""
            }`}
          >
            <span className="p-1 ps-2 pe-2 bg-danger d-inline-block">
              &nbsp;
            </span>
            <span className="ps-2 pe-2 text-nowrap">
              Closed<span className="d-none d-md-inline-block"> games</span>
            </span>
          </div>
          <div
            className={`rounded border border-success text-nowrap ${
              devicdeWidth < 480 ? " d-flex flex-column" : ""
            }`}
          >
            <span className="p-1 ps-2 pe-2 bg-secondary d-inline-block">
              &nbsp;
            </span>
            <span className="ps-2 pe-2 text-nowrap">
              Locked<span className="d-none d-md-inline-block"> games</span>
            </span>
          </div>
        </div>
      </GameFilter>

      <section className="pt-4">
        {/*  style={{height:"100cqh"}} */}

        <Modal
          contentClassName="bg-danger text-white"
          show={gameIsClosedModal}
          centered
          onHide={() => setGameIsClosedModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Game is closed</Modal.Title>
          </Modal.Header>
          <Modal.Body>The game you selected is closed;</Modal.Body>
        </Modal>

        <dialog ref={gameModal} id="gameDialog">
          {selectedGame && (
            <div className="d-flex flex-column h-100">
              <div className="d-flex align-items-center p-3 align-items-center">
                <h3 className="m-0 me-3">
                  Solve & Earn {selectedGame.prize} $
                </h3>
                <strong
                  className={`d-inline-block ps-3 pe-3 rounded text-white bg-${
                    selectedGame.status === "pending" ? "success" : "danger"
                  }`}
                >
                  {selectedGame.status === "pending" ? "Open" : "Closed"}
                </strong>
                {players.length > 0 && (
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-sm btn-ouline"
                      data-bs-toggle="dropdown"
                    >
                      <span className="badge bg-primary">{players.length}</span>{" "}
                      Players
                    </button>
                    <ul className="dropdown-menu">
                      {players.map((p) => (
                        <li key={p.username} className="dropdown-item">
                          <a>
                            {user.username === p.username ? "Me" : p.username}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <button
                  className="btn close ms-auto"
                  style={{ fontSize: "28px", fontWeight: "bold" }}
                  onClick={() => handleCloseGame()}
                >
                  &times;
                </button>
              </div>
              <div
                className={devicdeWidth > 720 ? "flex-fill" : ""}
                style={{ overflowY: "auto", height: "78cqh" }}
              >
                <Game {...{ selectedGame }} />
              </div>
            </div>
          )}
        </dialog>

        <Modal
          show={rewardInfo != null}
          centered
          onHide={() => setRewardInfo(null)}
        >
          <Modal.Header closeButton>
            <Modal.Title>You won!!!!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Add payment details to claim your price</p>
            <PaymentDetailsForm
              hideHeader={true}
              paymentDetails={rewardInfo}
            ></PaymentDetailsForm>
          </Modal.Body>
        </Modal>

        <div
          className="p-4 pt-2"
          style={{
            width: "100%",
            height: "calc(100cqh - 250px)",
            overflow: "auto",
          }}
        >
          <div
            className="d-flex flex-wrap games-grid justify-content-start"
            style={{
              width: `${
                (btnWidth + 8) * Math.floor(Math.sqrt(window.gridRowSize))
              }px`,
              margin: "0 auto",
            }}
          >
            {games.map((game, i) => (
              <div
                className="flex-fill"
                style={{ padding: "4px" }}
                key={`gslot-${game ? game.grid_location : i}`}
              >
                {game ? (
                  <>
                    <button
                      onMouseEnter={() =>
                        handleSlotHover({ slot: i, game: game.gameID }, i, game)
                      }
                      onClick={() =>
                        handleOpenGame({ ...game, slot: i }, i, game)
                      }
                      popovertarget={`popover$${1}`}
                      className={`p-0 btn btn-${
                        game.status === "complete" ? "danger" : "success"
                        } gameBtn`}
                        style={{
                          width: btnWidth,
                          height: btnWidth,
                          flexBasis: `${btnWidth}px`,
                          maxWidth: `${btnWidth}px`,
                          maxHeight: `${btnWidth}px`,
                          //overflow: "hidden",
                        }}
                    >
                      <img
                        alt=""
                        src={`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/static/small_${game.image}`}
                        className={`rounded`}
                        width={btnWidth}
                        height={btnWidth}
                        style={{ objectFit: "cover", padding: "2px" }}
                      />

                      <img
                        alt=""
                        src={`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/static/${game.image}`}
                        className="popedOver position-absolute rounded shadow m-3"
                        width={250}
                        height={250}
                        style={{ objectFit: "cover" }}
                      />
                    </button>
                  </>
                ) : (
                  <div
                    style={{
                      width: btnWidth+2,
                      height: btnWidth+2,
                        padding: "2px",
                        flexBasis: `${btnWidth}px`,
                        maxWidth: `${btnWidth}px`,
                        maxHeight: `${btnWidth}px`,
                    }}
                    onMouseEnter={() => handleSlotHover({ slot: i }, i, game)}
                    className="bg-light text-white rounded border border-white"
                  >
                    {/* {i + 1} */}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
