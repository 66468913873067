import { useEffect, useState } from "react";
import useApi from "../../hooks/api.hook";
//import ApiClient from "../../services/api-client.service";

export default function WonGames() {
  const apiClient = useApi(true);
  // const [wins, setWins] = useState([]);
  const [response, setResponse] = useState();
  //const api = new ApiClient();

  useEffect(() => {
    apiClient.get("/user/wins").then((response) => {
      setResponse(response);
    });
  }, []);

  return (
    <div
      id="won-games"
      className="d-flex flex-column flex-fill position-relative"
      style={{ maxHeight: "calc(100cqh - 180px)", overflow: "auto" }}
    >
      <table className="table table-sm table-striped table-responsive">
        <thead>
          <tr className="position-relative">
            <th className="position-sticky">Game</th>
            <th className="position-sticky">Reward</th>
            <th className="position-sticky">Paid</th>
            <th className="position-sticky">Details</th>
          </tr>
        </thead>
        <tbody>
          {response &&
            response.data.map((d, i) => (
              <tr key={d.id}>
                <td>
                  <img
                    src={`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/static/small_${d.game_instance.game_image.image}`}
                    width={30}
                    height={30}
                    style={{ objectFit: "cover" }}
                  />
                </td>
                <td>{d.game_instance.game_image.prize}</td>
                <td>{d.paid_amount || 0}</td>
                <td>
                  {JSON.parse(d.payment_details || "{}").paypalEmail || ""}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
