import { useEffect, useState } from "react";

export default function useAuth(){
    const authToken = window.sessionStorage.getItem('auth');
    const {accessToken, refreshToken} = JSON.parse(atob(authToken || btoa('{}')));
    const user = accessToken ? JSON.parse(atob(accessToken.split('.')[1])) : undefined;

    const [auth, setAuth] = useState({accessToken, refreshToken, user});

    useEffect(()=>{
        const sessionStorageUpdates = ()=>console.log("SS UPdate");
        window.addEventListener("storage", sessionStorageUpdates);
        return ()=>window.removeEventListener("storage", sessionStorageUpdates);
    }, []);

    return [auth, setAuth];
}