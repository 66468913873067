import { useState } from "react";
import useApi from "../../hooks/api.hook";
import { useNavigate } from "react-router-dom";

export default function PaymentDetailsForm({paymentDetails, hideHeader}){

    const [paymentData, setPaymentData] = useState(paymentDetails);
    const [response, setResponse] = useState();
    const apiClient = useApi(true);
    const navigate = useNavigate();

    function handleChange(e){
        setPaymentData({
            ...paymentData,
            [e.target.name]: e.target.value
        });
    }

    function handleSubmit(evt){
        evt.preventDefault();
        console.log(paymentData);
        apiClient.update("/user/claimreward", JSON.stringify(paymentData))
            .then(response=>{
                setResponse(response);
                if(!response.error){
                    navigate("/account/wins");
                }
            })
    }

    return (<form onSubmit={handleSubmit}>

        {!hideHeader && <h3>Add payment details</h3>}
        {response && <div className={`bg-${response.error ? 'danger' : 'success'} p-3 pt-2 pb-2`}>{response.message}</div>}
        <div className="form-group mb-3">
            <label className="">Enter your PayPal Email address</label>
            <input type="email" required name="paypalEmail" value={paymentData.paypalEmail} 
                onChange={handleChange} className="form-control" />
        </div>

        <div className="form-group">
            <button className="btn btn-primary">Submit Email</button>
        </div>

    </form>);
}