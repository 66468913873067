import { useContext } from "react";
import { UserContext } from "../../context/user.context";

export default function Profile(){
    const [user] = useContext(UserContext);
    return (<>
        {user && <div>
            <img alt="" src="./images/m.png" width={200} height={200} />
            <h3>{user.fullname}</h3>
            <div className="">
                <span>Username</span>
                <h5>{user.username}</h5>
            </div>
            <div className="">
                <span>Email</span>
                <h5>{user.email}</h5>
            </div>
            {/* <div className="">
                <span>Username</span>
                <h5>{user.username}</h5>
            </div> */}
        </div>}
    </>);
}