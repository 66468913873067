
export default async function useServiceWorker() {

    try {
        const serviceWorkerRegistration = await navigator.serviceWorker.register("/igammer.serviceworker.js", {
            scope: "/"
        });

        if (serviceWorkerRegistration.installing) {
            console.log("installing")
        }
        else if (serviceWorkerRegistration.waiting) {
            console.log("waiting")
        }
        else if (serviceWorkerRegistration.active) {
            console.log("active")
        }

        return serviceWorkerRegistration;
    }
    catch (e) {
        console.log(e);
    }

}