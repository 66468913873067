import { createContext, useEffect, useState } from "react";
import useApi from "../../hooks/api.hook";

export const WorkerContext = createContext({worker: window.socketWorker});
export default function WorkerProvider({ children }) {
    
    const api = useApi();
    // const [auth, setTokens] = useContext(AuthContext);
    const [workerMessage, setWorkerMessage] = useState();
    const worker = window.socketWorker;

    useEffect(()=>{
        worker.addEventListener("message", messageListener);
        return () => worker.removeEventListener("message", messageListener);
    }, []);

    function messageListener({ data }) {
        /* if (["token-expired", "reconnect"].includes(data)) {
            // if (auth) {
                // const { refreshToken } = auth;
                api.get('/auth/refreshtoken', {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${refreshToken}`
                }).then(tokens => setTokens(tokens));
            // }
        }
        else */ 
        if(typeof data === "object") setWorkerMessage(data);
    }

    return (<WorkerContext.Provider value={{ worker, workerMessage }}>{children}</WorkerContext.Provider>);
}
