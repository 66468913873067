import { Link, useLocation, useParams } from "react-router-dom";

export default function Error(){

    const {state} = useLocation();
    const params = useParams();

    return (<section className="container wrapper d-flex flex-column justify-content-center">
        <h3 className="">Error {params.code}</h3>
        <p>{state.message}</p>
        <div>
            <Link to={"/"} className="btn btn-danger">Go Home</Link>
        </div>
    </section>);
}