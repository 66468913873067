import { useEffect, useState } from "react";
import useApi from "../../../hooks/api.hook";
import { Link } from "react-router-dom";
//import ApiClient from "../../../services/api-client.service";

export default function Uploads() {
  const apiClient = useApi(true);
  const [loading, setLoading] = useState();
  const [images, setImages] = useState([]);
  //const api = new ApiClient();
  useEffect(() => {
    apiClient
      .get("/user/uploads")
      .then((response) => {
        if (!response.error) {
          setImages(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Link to={"/uploadgame"} className="btn btn-light">
        Upload Game
      </Link>
      <div className="d-md-flex flex-wrap">
        {loading
          ? "Loading..."
          : images.map((img, i) => (
              <div className="p-3" key={img.gameID}>
                <div className="bg-light rounded">
                  <img
                    alt=""
                    src={`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/static/${img.image}`}
                    width={"100%"}
                    height={150}
                    style={{ objectFit: "cover" }}
                  />
                  <div className="px-3">
                    <span className="text-black pe-3">Status:</span>
                    <br />
                    {img.status ? (
                      <span className="badge bg-success text-light">
                        {img.status[0].toUpperCase()}
                        {img.status.substring(1)}
                      </span>
                    ) : (
                      <span className="badge bg-warning text-light">
                        Pending
                      </span>
                    )}
                  </div>
                  <div className="p-3">
                    <Link
                      to={`/account/uploads/${img.gameID}`}
                      state={img}
                      className="btn btn-sm btn-outline-primary"
                    >
                      Game Stats
                    </Link>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </>
  );
}
