import { useLocation } from "react-router-dom";
import GameBoard from "../../components/game-board/GameBoard";
import { useContext, useEffect, useState } from "react";
import { WorkerContext } from "../../context/worker/worker.context";
//import ApiClient from "../../services/api-client.service";

export default function Play() {
  const {
    state: { category, game },
  } = useLocation();
  const [devicdeWidth, setDeviceWidth] = useState(800);
  const { worker } = useContext(WorkerContext);
  useEffect(() => {
    setDeviceWidth(document.body.offsetWidth);
  }, []);
  //const api = new ApiClient();


  function handleMoves(move) {
    console.log(move);
    worker.postMessage({
      action: "send",
      as: "game-move",
      type: "game-interaction",
      move: { ...move, game: game.gameId },
    });
  }

  function handleSolved(stats) {
    console.log(stats);
    worker.postMessage({
      action: "send",
      as: "game-complete",
      type: "check-winner",
      stats: { ...stats, game: game.gameId },
    });
  }

  return (
    <>
      <section className="container pt-4 d-md-flex">
        <div className="p-4 pt-0">
          <GameBoard
            rows={4}
            onMove={handleMoves}
            onSolved={handleSolved}
            image={`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/static/${game.image}`}
            width={400}
          />
        </div>
        <div
          className={`d-flex ${
            devicdeWidth > 480 ? "flex-column" : "justify-content-between w-100"
          } w-25 p-4 pt-0`}
        >
          <div>
            <span>Game Category</span>
            <h3>{category.categoryName}</h3>
          </div>
          <div>
            <span>Game Price</span>
            <h3>{game.prize}</h3>
          </div>
        </div>
      </section>
    </>
  );
}
