import { Outlet } from "react-router-dom";

export default function Authorization(){

    return (<>
        <Outlet />
    </>);

}

const auth = new Authorization();