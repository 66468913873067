import { useEffect, useRef, useState } from "react";
import "./game-board.css";

let tileState = {
    cursor: "pointer",
    zIndex: 1,

    top: 0,
    left: 0
}

export default function GameBoard({
    rows, image, width, onSolved = null, onFail = null, onHover, onMove }) {

    const [tiles, setTiles] = useState([]);
    const [canMove, setCanMove] = useState(false);
    const [movingTile, setMovingTile] = useState();
    const [emptyTile, setEmptyTile] = useState();
    const [initPos, setInitPos] = useState();
    const [totalTiles, setTotalTiles] = useState(rows ** 2);
    const neibours = useRef({});
    const [isSet, setIset] = useState(false);
    const gameboard = useRef(null);
    const [movesCount, setMovesCount] = useState(0);
    const [startTime, setStartTime] = useState();
    const [timer, setTimer] = useState();
    const [isDone, setIsDone] = useState(false);
    const [isRandomized, setIsRandomized] = useState(false);

    const [gameboardWidth, setGameboardWidth] = useState();

    // For initializing the game board
    useEffect(() => {

        if (tiles.length === 0) {
            initialize();
        }
        else if (!isSet) {
            setIset(true);
            setTimeout(() => {
                randomize(tiles);
            }, 0);
        }

        setIsDone(isRandomized && solved());


    }, [tiles, isSet]);

    // For monitoring timer
    useEffect(() => {
        let timerInterval = setInterval(() => {
            if (startTime && !isDone) {
                const now = new Date();
                let timeDifference = now.getTime() - startTime.getTime();

                const hours = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor(((timeDifference % (1000 * 60 * 60)) % (1000 * 60)) / 1000);

                setTimer({ hours, minutes, seconds });
            }
        }, 1000);

        return function () {
            clearInterval(timerInterval);
            timerInterval = null;
        }
    }, [startTime, isDone]);

    // To report game complete or failed
    useEffect(() => {

        if (isDone && solved() && onSolved) {
            onSolved({
                moves: movesCount,
                timeTaken: timer
            });
        }

        if (isDone && !solved() && onFail) {
            onFail({
                moves: movesCount,
                timeTaken: timer
            });
        }

    }, [movesCount, isDone]);

    useEffect(() => {
        if (!startTime) {
            setTotalTiles(rows ** 2);
            setIsRandomized(false);
            setIset(false);
            setTiles([]);
        }
    }, [rows, startTime]);

    useEffect(() => {
        const gameBoardWidthEvent = () => {
            const gameBoardStyle = gameboard.current.currentStyle || window.getComputedStyle(gameboard.current);
            setGameboardWidth((width || gameboard.current.offsetWidth) - (parseFloat(gameBoardStyle.marginLeft) + parseFloat(gameBoardStyle.marginRight) + parseFloat(gameBoardStyle.borderLeftWidth) + parseFloat(gameBoardStyle.borderRightWidth)));
        }
        window.addEventListener("resize", gameBoardWidthEvent);
        return () => window.removeEventListener("resize", gameBoardWidthEvent);
    }, []);

    function initialize() {
        const gameBoardStyle = gameboard.current.currentStyle || window.getComputedStyle(gameboard.current);
        setGameboardWidth((width || gameboard.current.offsetWidth) - (parseFloat(gameBoardStyle.marginLeft) + parseFloat(gameBoardStyle.marginRight) + parseFloat(gameBoardStyle.borderLeftWidth) + parseFloat(gameBoardStyle.borderRightWidth)));
        const tileSize = gameboardWidth / rows;
        gameboard.current.style.height = gameboardWidth + 'px';

        let row = 0;
        const emptyTiles = new Array(totalTiles).fill(0);
        const tls = emptyTiles.map((_, i) => {
            if (i > 0 && i % rows === 0) row++;
            const col = i % rows;
            const tilesInSameColumn = Object.keys(emptyTiles).filter((j) => j % rows === col);
            const tilesInSameRow = Object.keys(emptyTiles).filter((k) => {
                const rowOfK = k === 0 ? 0 : Math.floor(k / rows);
                return row === rowOfK;
            });

            neibours.current[i] = tilesInSameColumn.reduce((b, l) => {
                return parseInt(l) - rows === parseInt(i) || parseInt(l) + rows === parseInt(i) ? [...b, parseInt(l)] : b;
            }, []).concat(tilesInSameRow.reduce((c, m) => {
                return parseInt(m) - 1 === parseInt(i) || parseInt(m) + 1 === parseInt(i) ? [...c, parseInt(m)] : c;
            }, []));

            return {
                originalIndex: i,
                currentIndex: i,
                id: Math.floor(Math.random() * 10002),
                width: `${tileSize}px`,
                height: `${tileSize}px`,
                ...tileState,
                top: `${tileSize * row}px`,
                left: `${tileSize * (i % rows)}px`,
                backgroundImage: `url(${image})`,
                backgroundSize: `${gameboard.current.offsetWidth}px auto`,
                backgroundPositionY: `-${tileSize * row}px`,
                backgroundPositionX: `-${tileSize * (i % rows)}px`
            }
        });
        setTiles(tls);
        setEmptyTile(tls.at(-1));

        let hours = 0, minutes = 0, seconds = 0;
        setTimer({ hours, minutes, seconds });
    }

    function randomize(tls) {

        let empty = { ...emptyTile }

        const randomizeFunc = () => {

            const theMovables = neibours.current[empty.originalIndex];
            const indexOfMoving = theMovables[Math.floor(Math.random() * theMovables.length)];
            const moving = tls[indexOfMoving];

            tls[empty.originalIndex] = {
                ...tls[empty.originalIndex],
                currentIndex: moving.currentIndex,
                backgroundImage: moving.backgroundImage,
                backgroundSize: moving.backgroundSize,
                backgroundPositionY: moving.backgroundPositionY,
                backgroundPositionX: moving.backgroundPositionX
            };

            tls[moving.originalIndex].currentIndex = empty.currentIndex;

            setTiles(tls);
            empty = {
                ...tls[moving.originalIndex],
                currentIndex: moving.currentIndex,
                id: moving.id
            }
            setEmptyTile(empty);
        }

        let count = 100;

        while (count > 0) {
            randomizeFunc();
            count--;
        }

        setIsRandomized(true);
    }

    function solved() {
        for (let i = 0; i < tiles.length; i++) {
            if (i !== tiles[i].currentIndex) return false;
        }
        return true;
    }

    function handleMouseDown(tile, evt = null) {

        if (!isRandomized || isDone) return;

        if (!movingTile && neibours.current[emptyTile.originalIndex].includes(tile.originalIndex)) { //&& tile.id != emptyTile.id
            setMovingTile({ ...tile });
            setCanMove(neibours.current[emptyTile.originalIndex].includes(tile.originalIndex));

            if (evt) {
                setInitPos({
                    mX: evt.clientX,
                    mY: evt.clientY,
                    tX: Number(tile.left.replace(/[a-z]/gi, '')),
                    tY: Number(tile.top.replace(/[a-z]/gi, ''))
                });
            }
        }
    }

    function handleMouseUp() {
        if (canMove) {
            setCanMove(false);
            swap();
            setInitPos(null);

            setMovesCount(movesCount => movesCount + 1);

            if (!startTime) {
                setStartTime(new Date());
                setTimer({ hours: 0, minutes: 0, seconds: 0 });
            }

            if (onMove) onMove({ currentIndex: movingTile.currentIndex, time: (new Date()).getTime() });
        }
    }

    function handleMouseMove(evt) {

        if (canMove && movingTile && initPos) {
            const indexOfMoving = tiles.findIndex(t => t.id === movingTile.id);
            if (!neibours.current[emptyTile.originalIndex].includes(indexOfMoving)) return;

            const emptyTilePos = {
                x: Number(emptyTile.left.replace(/[a-z]/gi, '')),
                y: Number(emptyTile.top.replace(/[a-z]/gi, ''))
            }
            const tileLeft = Number(movingTile.left.replace(/[a-z]/gi, ''));
            const tileTop = Number(movingTile.top.replace(/[a-z]/gi, ''));

            const isHMovement = emptyTile.top === movingTile.top;
            const isVMovement = emptyTile.left === movingTile.left;
            const xConstraint = [emptyTilePos.x, tileLeft].sort((a, b) => a - b);
            const yConstraint = [emptyTilePos.y, tileTop].sort((a, b) => a - b);

            const xDiff = evt.clientX - initPos.mX;
            const yDiff = evt.clientY - initPos.mY;
            let newX = initPos.tX, newY = initPos.tY;

            if (isHMovement) {
                if (xDiff >= 0) {
                    const xHolder = newX + Math.abs(xDiff);
                    if (xHolder > xConstraint[0] && xHolder < xConstraint[1]) newX = xHolder;
                }
                else {
                    const xHolder = newX - Math.abs(xDiff);
                    if (xHolder > xConstraint[0] && xHolder < xConstraint[1]) newX = xHolder;
                }
            }

            if (isVMovement) {
                if (yDiff >= 0) {
                    const yHolder = newY + Math.abs(yDiff);
                    if (yHolder > yConstraint[0] && yHolder < yConstraint[1]) newY = yHolder;
                }
                else {
                    const yHolder = newY - Math.abs(yDiff);
                    if (yHolder > yConstraint[0] && yHolder < yConstraint[1]) newY = yHolder;
                }
            }

            setTiles((tls) => {
                const nTls = [...tls];
                nTls[indexOfMoving] = { ...movingTile, left: `${newX}px`, top: `${newY}px` }
                return nTls;
            });
        }

        if (onHover) onHover();
    }

    function swap() {
        if (!movingTile) return;

        setTiles(tls => {
            let nTls = [...tls];
            nTls[emptyTile.originalIndex] = {
                ...nTls[emptyTile.originalIndex],
                left: emptyTile.left,
                top: emptyTile.top,
                currentIndex: movingTile.currentIndex,
                backgroundImage: movingTile.backgroundImage,
                backgroundSize: movingTile.backgroundSize,
                backgroundPositionY: movingTile.backgroundPositionY,
                backgroundPositionX: movingTile.backgroundPositionX
            }

            nTls[movingTile.originalIndex] = {
                ...nTls[movingTile.originalIndex],
                currentIndex: emptyTile.currentIndex,
                left: `${initPos.tX}px`,
                top: `${initPos.tY}px`
            }

            return nTls;
        })

        setEmptyTile({
            ...movingTile,
            currentIndex: emptyTile.currentIndex
        });
        setMovingTile(null);
    }

    function isEmptyTile(tile) {
        return tile.originalIndex === emptyTile.originalIndex;
    }

    function isMovableTile(tile) {
        return neibours.current[emptyTile.originalIndex].includes(tile.originalIndex);
    }

    return <div className="puzzle-container">
        <div className="game-stats" style={gameboard.current ? { width: `${width || gameboard.current.offsetWidth}px` } : { width }}>
            {timer && <><div className="moves">
                <strong>{movesCount}</strong><div><small>Moves</small></div>
            </div>
                <div className="timer">
                    <strong>
                        {timer.hours.toString().padStart(2, '0')}:
                        {timer.minutes.toString().padStart(2, '0')}:
                        {timer.seconds.toString().padStart(2, '0')}
                    </strong>
                    <div><small>Timer</small></div>
                </div></>}
        </div>

        <div className="game-board" style={{ width }}
            onTouchMoveCapture={(e) => handleMouseMove(e)}
            onMouseMove={(e) => handleMouseMove(e)}
            ref={gameboard}
        >

            {emptyTile && tiles.length > 0 && tiles.map((tile, i) => <div
                key={tile.id}
                className={`tile ${isMovableTile(tile) ? 'movable-tiles' : (isEmptyTile(tile) ? 'empty-tile' : '')}`}
                style={{
                    ...tile,
                    ...(isEmptyTile(tile) ? { backgroundImage: 'none' } : {})
                }}
                onMouseDown={(e) => handleMouseDown(tile, e)}
                onMouseUp={() => handleMouseUp()}
            >
                {/* <div> */}
                    {isEmptyTile(tile) ? <>
                        <div>&nbsp;</div>
                        <div>&#8681;</div>
                        <div>&nbsp;</div>
                        <div>&#8680;</div>
                        <div className="empty-center">Empty Box</div>
                        <div>&#8678;</div>
                        <div>&nbsp;</div>
                        <div>&#8679;</div>
                        <div>&nbsp;</div>
                    </> : ''}
                {/* </div> */}
            </div>)
            }
        </div>

        {/* {isRandomized && solved() && <h1>Solved</h1>} */}
        {/* {isRandomized && !solved() && isDone && <h1>Game Over</h1>} */}
    </div>

}