import { useNavigate } from "react-router-dom";
import ApiClient from "../services/api-client.service";
import useAuth from "./auth.hook";
import { useContext } from "react";
import { AuthContext } from "../context/auth.context";

export default function useApi(secure) {
  const apiClient = new ApiClient(secure);
  const [auth, setAuth] = useAuth();
  const [, setTokens] = useContext(AuthContext);
  const navigate = useNavigate();

  function refreshToken() {
    return fetch(`${apiClient.apiBase}/auth/refreshtoken`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.refreshToken}`,
      },
    })
      .then((resp) => {
        if (resp.status === 200) return resp.json();
        throw new Error(
          JSON.stringify({ status: resp.status, message: resp.statusText })
        );
      })
      .then((response) => {
        // console.log("in token refresh", response);
        if (response && !response.error) {
          setTokens(response.data);
          return response;
        }
        throw new Error(response.message);
      });
  }

  function hundleError(e, req) {
    try {
      const errorPayload = JSON.parse(e.message);
      // console.log(errorPayload);
      if (
        errorPayload.status === 401 &&
        errorPayload.message === "jwt expired" &&
        req
      ) {
        refreshToken()
          .then(() => req())
          .catch(hundleError);
      } else if (errorPayload.status === 401) {
        setAuth(undefined);
        navigate("/auth/signin");
      } else navigate(`/error/${errorPayload.status}`, { state: errorPayload });
    } catch (ne) {
      navigate(`/error/${500}`, { state: ne });
    }
  }

  return {
    async get(path, headers) {
      const request = () =>
        new Promise((rs, rj) => {
          apiClient
            .get(path, headers)
            .then(rs)
            .catch((e) => hundleError(e, () => request().then(rs)));
        });
      return request();
    },

    async create(path, body, headers) {
      const request = () =>
        new Promise((rs, rj) => {
          apiClient
            .create(path, body, headers)
            .then(rs)
            .catch((e) => hundleError(e, () => request().then(rs)));
        });
      return request();
    },

    async update(path, body, headers) {
      const request = () =>
        new Promise((rs, rj) => {
          apiClient
            .update(path, body, headers)
            .then(rs)
            .catch((e) => hundleError(e, () => request().then(rs)));
        });

      return request();
    },
  };
}
