export default function gamesReducer(games, {type, game}){
    const copyOfGames = [...games];
    switch(type){
        case "updateById":
            const gameIndex = copyOfGames.findIndex(g=>game.id === g.id);
            copyOfGames.splice(gameIndex, 1, game)
        break;
        case "updateByGridLocation":
            copyOfGames.splice(game.grid_location, 1, game)
        break;
        case "removeFromSlot":
            copyOfGames.splice(game.grid_location, 1, undefined)
        break;
        case "removeAll":
            return Array.from(new Array(copyOfGames.length), ()=>undefined);
        break;
    }

    return copyOfGames;
}