import { useEffect, useState } from "react";
import { Dropdown } from "bootstrap";
import { Link, useParams } from "react-router-dom";
import useApi from "../../hooks/api.hook";
//import ApiClient from "../../services/api-client.service";

export default function GameFilter({ children, category }) {
  const apiClient = useApi();
  const [categories, setCategories] = useState([]);
  const pathParams = useParams();
  //const api = new ApiClient();

  useEffect(() => {
    apiClient.get("/parent_categories").then((response) => {
      if (!response.error) {
        setCategories(response.data);
      }
    });
  }, [pathParams]);

  return (
    <section className="container d-flex pt-4 align-items-center">
      <div className="dropdown me-md-3 me-sm-2">
        <button
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
        >
          {category ? category.categoryName : "All Categories"}
        </button>
        <ul
          className="dropdown-menu p-0 "
          id="categories"
          style={{
            maxHeight: "80dvh",
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          <li className="dropdown-item" key={"category-all"}>
            <Link
              to={`/games`}
              className="btn dropdown-link d-flex align-items-center"
              state={{}}
            >
              <h4 className="ms-4">All Categories</h4>
            </Link>
          </li>
          <li key={"divider-org"}>
            <hr className="dropdown-divider" />
          </li>
          {categories.map((category, i) => (
            <li
              className="dropdown-item"
              key={category.parentCategoryID + Math.random().toString()}
            >
              <Link
                to={`/games/${category.parentCategoryID}`}
                className="btn dropdown-link d-flex align-items-center border-bottom"
                state={{ category }}
              >
                <img
                  alt=""
                  src={`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/static/${category.image}`}
                  width={100}
                  height={50}
                  style={{ objectFit: "cover" }}
                />
                <h4 className="ms-4">{category.categoryName}</h4>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {children}
    </section>
  );
}
