import Auth from "../models/auth";

export default class ApiClient {
  constructor(secure = false) {
    this.apiBase = process.env.REACT_APP_PRODUCTION
      ? process.env.REACT_APP_PRODUCTION_API_BASE_URL
      : process.env.REACT_APP_API_BASE_URL;
    this.secure = secure;
  }

  get headers() {
    const auth = Auth.decode();
    return {
      ...(this.secure && auth && auth.accessToken
        ? { Authorization: `Bearer ${auth.accessToken}` }
        : {}),
      "Content-Type": "application/json",
    };
  }

  async response(res) {
    if (res.status >= 200 && res.status < 300) return res.json();
    return res.json().then((body) => {
      throw new Error(JSON.stringify(body));
    });
  }

  async get(path, headers) {
    return fetch(`${this.apiBase}${path}`, {
      headers: headers || this.headers,
    }).then(this.response); //.catch(e=>console.log(e))
  }

  async create(path, body, headers) {
    return fetch(`${this.apiBase}${path}`, {
      method: "POST",
      body, //: JSON.stringify(body),
      headers: headers || this.headers,
    }).then(this.response);
  }

  async update(path, body, headers) {
    return fetch(`${this.apiBase}${path}`, {
      method: "PATCH",
      body,
      headers: headers || this.headers,
    }).then(this.response);
  }
}
