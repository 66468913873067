import { useContext, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth.context";
import "./account.css";
import { DisplayContext } from "../../context/display.context";

export default function Account() {

    const [auth, setAuth] = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const devicdeWidth = useContext(DisplayContext);

    useEffect(() => {
        if (!auth || !auth.accessToken) {
            navigate('/auth/signin')
        }
    }, [auth, location.pathname]);

    function handleSignOut(){
        setAuth(undefined);
    }

    return (<>
        <section className="container d-md-flex mt-4">
            <div className="account-nav position-sticky"> {/*overflow-auto*/ }
                <ul className={`${devicdeWidth > 768 ? 'list-group list-group-flush' : 'nav flex-nowrap'} bg-none`}>
                    <li className={`${devicdeWidth > 768 ? 'list-group-item text-light' : 'nav-item'}`} style={{ background: 'none' }}><Link to="/account" className={`btn text-white ${devicdeWidth > 768 ? '' : 'nav-link'} text-nowrap`}>My Profile</Link></li>
                    <li className={`${devicdeWidth > 768 ? 'list-group-item text-light' : 'nav-item'}`} style={{ background: 'none' }}><Link to="/account/uploads" className={`btn text-white ${devicdeWidth > 768 ? '' : 'nav-link'} text-nowrap`}>My Uploaded Images</Link></li>
                    <li className={`${devicdeWidth > 768 ? 'list-group-item text-light' : 'nav-item'}`} style={{ background: 'none' }}><Link to="/account/wins" className={`btn text-white ${devicdeWidth > 768 ? '' : 'nav-link'} text-nowrap`}>Won Games</Link></li>
                    {/*<li className={`${devicdeWidth > 768 ? 'list-group-item text-light' : 'nav-item'}`} style={{ background: 'none' }}><Link to="/account/settings" className={`btn text-white ${devicdeWidth > 768 ? '' : 'nav-link'} text-nowrap`}>Settings</Link></li>*/}
                    <li className={`${devicdeWidth > 768 ? 'list-group-item text-light' : 'nav-item'}`} style={{ background: 'none' }}><Link onClick={handleSignOut} className={`btn text-white ${devicdeWidth > 768 ? '' : 'nav-link'} text-nowrap`}>Logout</Link></li>
                </ul>
            </div>

            <div className="ps-4 flex-fill">
                <Outlet />
            </div>
        </section>
    </>);
}