import { createContext, useEffect, useState } from "react";

export const DisplayContext = createContext(window.innerWidth);
export default function DisplayProvider({children}){
    
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    useEffect(()=>{
        const deviceWidthEvent = ()=>setDeviceWidth(window.innerWidth);
        window.addEventListener("resize", deviceWidthEvent);
        return ()=>window.removeEventListener("resize", deviceWidthEvent);
    }, []);

    return (<DisplayContext.Provider value={deviceWidth}>{children}</DisplayContext.Provider>);
}