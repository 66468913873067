import { useContext, useEffect, useState } from "react";
import "./explore.css";

import { Link } from "react-router-dom";
import useApi from "../../hooks/api.hook";
import { DisplayContext } from "../../context/display.context";
import { numberToString } from "../../services/helpers";
//import ApiClient from "../../services/api-client.service";

export default function Explore() {
  const apiCLient = useApi();
  const [categories, setCategories] = useState();
  const windowWidth = useContext(DisplayContext);
  //const api = new ApiClient();

  useEffect(() => {
    apiCLient.get("/parent_categories").then((response) => {
      console.log(response);
      if (!response.error) {
        setCategories(response.data);
      }
    });
  }, []);

  // Function to calculate the sum of the cash_price field
  const calculateSum = (gameImages) => {
    if (!gameImages || gameImages.length === 0) return 0;

    return gameImages.reduce((acc, image) => {
      const cashPrize = parseInt(image.cash_prize);
      //console.log("Image: ", image);
      //console.log("Cash Price: ", cashPrize);
      return acc + (isNaN(cashPrize) ? 0 : cashPrize);
    }, 0);
  };

  const calculateInstanceSum = (gameImages) => {
    if (!gameImages || gameImages.length === 0) return 0;

    return gameImages.reduce((accb, image) => {
      const instance = parseInt(image.instances);
      //console.log("Image: ", image);
      //console.log("Cash Price: ", cashPrize);
      return accb + (isNaN(instance) ? 0 : instance);
    }, 0);
  };

  return (
    <>
      <section className="container pt-4">
        <h4>Game Categories</h4>
      </section>
      <section
        className={`container explorer pt-4 d-flex ${
          windowWidth < 480 ? "flex-column" : "flex-wrap"
        }`}
      >
        {categories ? (
          categories.map((category, i) => (
            <div
              key={category.parentCategoryID + i}
              className={`p-3 ${
                windowWidth < 480 ? "w-100" : windowWidth < 720 ? "w-50" : ""
              }`}
            >
              <div className="w-100 d-flex flex-column shadow rounded bg-white position-relative">
                {category.games > 0 && (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {numberToString(
                      category.game_images.length
                        ? calculateInstanceSum(category.game_images) //category.games
                        : 0
                    )}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                )}
                <img
                  alt=""
                  src={`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/static/${category.image}`}
                />
                <Link
                  className="btn text-left"
                  to={`/games/${category.parentCategoryID}`}
                  state={{ category }}
                >
                  <h3 className="p-3 pb-0 pt-1">{category.categoryName}</h3>
                </Link>
                <div className="d-flex justify-content-between">
                  <div
                    className={`p-3 pt-0 pb-0 text-center ${
                      windowWidth > 480 ? "w-50" : ""
                    }`}
                  >
                    <small className="text-nowrap">
                      {windowWidth > 480 && "Active"} Games
                    </small>
                    <br />
                    <strong className="text-nowrap">
                      {numberToString(
                        category.game_images.length
                          ? calculateInstanceSum(category.game_images) //category.games
                          : 0
                      )}
                    </strong>
                  </div>
                  <div
                    className={`p-3 pt-0 pb-0 text-center ${
                      windowWidth > 480 ? "w-50" : ""
                    }`}
                  >
                    <small className="text-nowrap">
                      {windowWidth > 480 && "Earn"} Amount
                    </small>
                    <br />
                    <strong className="text-nowrap">
                      USD{" "}
                      {numberToString(
                        category.game_images.length
                          ? calculateSum(category.game_images) //category.game_images[0].cash_prize
                          : 0
                      )}
                    </strong>
                  </div>
                </div>
                <Link
                  to={`/games/${category.parentCategoryID}`}
                  className="btn btn-light"
                  state={{ category }}
                >
                  Explore
                </Link>
              </div>
            </div>
          ))
        ) : (
          <div className="w-100 d-flex flex-column align-items-center justify-content-center">
            <div className="text-white">Loading Categories</div>
          </div>
        )}
      </section>
    </>
  );
}
