import { useContext, useEffect, useReducer, useRef, useState } from "react";
import GridLocation from "./GridLocation";
import { DisplayContext } from "../../context/display.context";
import useApi from "../../hooks/api.hook";
import { useLocation } from "react-router-dom";
import gamesReducer from "../../reducers/games.reducer";

export default function GridLocations({ onSelect, category }) {

    const gridLocationRef = useRef();
    const [locationWidth, setLocationWidth] = useState();
    const { state } = useLocation();
    const [games, gameDispatch] = useReducer(gamesReducer, Array(window.gridRowSize).fill());
    const [selectedLocation, setSelectedLocation] = useState([]);
    const deviceWidth = useContext(DisplayContext);
    const apiClient = useApi(true);
    const [btnWidth, setBtnWidth] = useState(0);

    /* useEffect(() => {
        if(blockedSlots.length > 0){
            console.log(gridLocations.length);
            setGridLocations(prev=>{
                let fresh = [...prev];
                blockedSlots.forEach(slot=>fresh.splice(slot.grid_location-1, 1));
                console.log(fresh.length);
                return fresh;
            });
        }
    }, [blockedSlots]); */

    useEffect(() => {
        if (category) {
            gameDispatch({type: "removeAll"});
            apiClient.get(`/game_instances/up?parentCategoryID=${category.parentCategoryID}`).then(response => {
                if (!response.error) {
                    response.data.forEach(game => gameDispatch({ type: "updateByGridLocation", game }));
                }
            });
        }

        setBtnWidth(document.querySelector('#tiiiidi button').offsetWidth + 4);

        setLocationWidth(gridLocationRef.current.offsetWidth / 15);
    }, [deviceWidth, category]);

    function toggleLocation(loc) {

        setSelectedLocation(prevSelection => {
            const currentSelection = [...prevSelection];
            const pozIndex = currentSelection.findIndex((inx) => inx === loc);
            if (pozIndex != -1) {
                currentSelection.splice(pozIndex, 1);
            }
            else {
                currentSelection.push(loc);
            }
            return currentSelection;
        });
        if (onSelect) onSelect(loc);

    }

    return (<div className="form-group mb-5" id="tiiiidi">
        <div ref={gridLocationRef} className="d-flex flex-wrap" style={{ width: `${btnWidth * Math.floor(Math.sqrt(window.gridRowSize))}px` }}>
            {games.map((g, slot) => <div key={slot}
                style={{ padding: "2px", width: `calc((${locationWidth}px + 4px) / 16px)em !important` }}
                className="d-flex">
                <GridLocation location={slot} isSelected={selectedLocation.includes(slot)} onSelect={toggleLocation} game={g} />
                <input type="checkbox" name="game_slots[]" onChange={(evt) => { }} className="d-none" checked={selectedLocation.includes(slot)} value={slot} />
            </div>)}
        </div>
    </div>);
}