import { useContext, useState } from "react";
import ApiClient from "../../services/api-client.service";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { DisplayContext } from "../../context/display.context";
import useApi from "../../hooks/api.hook";

export default function Signup() {

    const [userData, setUserData] = useState({
        fullname:'',
        gender: '',
        birthdate: '',
        username:'',
        email: '',
        password: '',
        confirmPassword:''
    });
    const [validation, setValidation] = useState();
    const [signedup, setSignedup] = useState(false);
    const apiClient =  new ApiClient();

    const deviceWidth = useContext(DisplayContext);

    function handleSubmit(evt) {
        evt.preventDefault();
        setValidation(undefined);

        apiClient.create('/auth/signup', JSON.stringify(userData)).then(response => {
            // navigate('/auth/singin')
            if(!response.error){
                setSignedup(true);
            }
            else{
                setValidation({ error: response.message });
            }
        })
            .catch(e => {
                try{    
                    let error = JSON.parse(e.message);
                    setValidation({ error: error.message });
                }
                catch(er){
                    setValidation({ error: e.message });
                }
            })

    }

    function handleField(evt) {
        setUserData({
            ...userData,
            [evt.target.name]: evt.target.value
        });
    }

    return (<>

        <Modal show={signedup} centered>
            <Modal.Body>
                <h2>Sign up successful!</h2>
                <p>Check your email to verify your account. If the email is not in your inbox check under spam emails</p>
            </Modal.Body>
            <Modal.Footer>
                <Link to="/auth/signin" className="btn btn-success">Okey</Link>
            </Modal.Footer>
        </Modal>
        
        <section className="wrapper container h-100 d-flex justify-content-center">
            {/* <div className="w-50 p-5">
                Left
            </div> */}

            <form className={`p-5 ${deviceWidth < 480 ? '' : 'w-50'}`} onSubmit={handleSubmit}>
                <p>By filling up this form, you agree to the <Link to="/termsofuse">terms and conditions stated here</Link></p>
                <Link to="/auth/signin" className="btn text-nowrap text-white ps-0">Already have an account? Sign In</Link>
                {validation && <div className="p-3 mb-3 mt-3 bg-danger">{validation.error}</div>}
                <div className="form-group mb-3">
                    <label>Full Name</label>
                    <input type="text" name="fullname" value={userData.fullname} onChange={handleField} className="form-control" />
                </div>


                <div className="d-md-flex">
                    <div className={`form-group mb-3 ${deviceWidth > 480 && ' me-3 w-50'}`}>
                        <label>Gender</label>
                        <select name="gender" value={userData.gender} onChange={handleField} className="form-control">
                            <option value=""></option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                        </select>
                    </div>


                    <div className={`form-group mb-3 ${deviceWidth > 480 && ' w-50'}`}>
                        <label>Date of Birth</label>
                        <input type="date" name="birthdate" value={userData.birthdate} onChange={handleField} className="form-control" />
                    </div>
                </div>

                <hr />

                <div className="form-group mb-3">
                    <label>Username</label>
                    <input type="text" name="username" value={userData.username} onChange={handleField} className="form-control" />
                </div>

                <div className="form-group mb-3">
                    <label>Email</label>
                    <input type="email" name="email" value={userData.email} onChange={handleField} className="form-control" />
                </div>

                <div className="d-md-flex">

                    <div className={`form-group mb-3 ${deviceWidth > 480 && ' me-3 w-50'}`}>
                        <label>Password</label>
                        <input type="password" name="password" value={userData.password} onChange={handleField} className="form-control" />
                    </div>

                    <div className={`form-group mb-3 ${deviceWidth > 480 && 'w-50'}`}>
                        <label>Confirm Password</label>
                        <input type="password" name="confirmPassword" value={userData.confirmPassword} onChange={handleField} className="form-control" />
                    </div>
                </div>


                <div className="form-group d-flex justify-conent-between">
                    <button className="btn btn-primary text-nowrap w-100">Sign Up</button>
                </div>
            </form>
        </section>
    </>);

}