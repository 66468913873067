import { useContext, useEffect, useRef, useState } from "react";
import { DisplayContext } from "../../context/display.context";

import "./file-selector.css";

export default function ImageSelector({ onImageRepositioned }) {

    const [imgFile, setImgFile] = useState();
    const gameImage = useRef(<img width={window.maxImageSize} height={window.maxImageSize} />);
    const [image, setImage] = useState();
    const [imageSize, setImageSize] = useState({ width: window.maxImageSize, height: window.maxImageSize });
    const [cropArea, setCropArea] = useState({ x: 0, y: 0 });

    useEffect(() => {
        if (onImageRepositioned && gameImage.current) {
            onImageRepositioned({
                ...cropArea,
                width: gameImage.current.width,
                height: gameImage.current.height
            });
        }
    }, [cropArea, imageSize]);

    function hundleImageSelect(evt) {
        const selectedFile = evt.target.files[0];
        setImgFile(selectedFile);
        const img = new Image();
        // img.width = gameImage.current.with;
        // img.height = gameImage.current.height;
        img.onload = () => {
            setImage(img);
            gameImage.current.width = window.maxImageSize;
            gameImage.current.height = img.height * (window.maxImageSize / img.width);
            onImageRepositioned({
                ...cropArea,
                width: gameImage.current.width,
                height: gameImage.current.height
            });
        }
        img.src = URL.createObjectURL(evt.target.files[0]);
    }

    function hundleScroll(evt) {
        const scrolledTarget = evt.target;
        setCropArea({ x: scrolledTarget.scrollLeft, y: scrolledTarget.scrollTop })
    }

    function resizeImage(dir = 'in') {

        const currentWidth = gameImage.current.width;
        let width = dir === 'in' ? currentWidth + 10 : currentWidth - 10;
        let height = gameImage.current.height * (width / currentWidth);

        setImageSize({ width, height });
    }

    return (<>
        <div id="gameImageUploadHolder" onScroll={hundleScroll}
            className={`border ${!image ? 'd-flex flex-column align-items-center justify-content-center' : ''}`}>
            <img id="imageToUpload" ref={gameImage} src={image ? image.src : '/images/m.png'} {...imageSize} style={{ objectFit: "fill" }} />
            {/* {!image && <div>
                <input type="file" name="image" className="form-control" onChange={hundleImageSelect} />
            </div>} */}
        </div>
        {image && <><div className="align-items-center">
            <div className="d-flex mb-1">
                <button type="button" className="btn btn-sm btn-light flex-fill" title="zoom in" onClick={() => resizeImage('in')}>
                    + Zoom In
                </button>
                <button type="button" className="btn btn-sm btn-light flex-fill" title="zoom out" onClick={() => resizeImage('out')}>
                    - Zoom Out
                </button>
            </div>

            <div className="me-auto pe-2 pb-2">
                <span>Move the image to fit the frame and zoom to the right place</span>
            </div>
        </div>{image && <div className="w-100 d-flex justify-content-between">
            <div>
                <strong>Dimensions</strong><br />
                {gameImage.current && <>{gameImage.current.width}px X {gameImage.current.height}px</>}
            </div>
            <div>
                <strong>Cropping Area</strong><br />
                {cropArea.x}x : {cropArea.y}y
            </div>
        </div>}</>}
        {/* <div className={` d-flex flex-column`}>
            {imgFile && <>
                <div className="mb-2">
                    <strong>Name</strong><br />
                    {imgFile.name}
                </div>
            </>}
        </div> */}

        <div className="mt-auto">
            <label htmlFor="">{imgFile ? 'Change' : 'Select'} Image</label>
            <input type="file" required name="image" accept="image/*" className="form-control" onChange={hundleImageSelect} />
        </div>
    </>);
}