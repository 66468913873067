import { useContext, useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import "./uploadGame.css";
import { DisplayContext } from "../../context/display.context";
import GridLocations from "../../components/gridLocations/GridLocations";
import ImageSelector from "../../components/image-uploader/ImageSelector";
import { AuthContext } from "../../context/auth.context";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { cropImage, randomString } from "../../services/helpers";
import useApi from "../../hooks/api.hook";
import ApiClient from "../../services/api-client.service";

export default function UploadGame() {

    const deviceWidth = useContext(DisplayContext);
    const [cropInfo, setCropInfo] = useState();

    const [auth] = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const apiClient = useApi(true);
    const [modal, setModal] = useState();
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(state && state.category ? state.category : null);

    useEffect(() => {
        if (!auth || !auth.accessToken) {
            navigate('/auth/signin?return_url=/uploadgame')
        }

        if (categories.length === 0) {
            apiClient.get('/parent_categories')
                .then(response => {
                    if (!response.error) {
                        setCategories(response.data);
                    }
                })
        }

    }, [location.pathname, auth]);

    function handleUpload(evt) {
        evt.preventDefault();
        setModal(undefined);

        if (cropInfo.width < window.maxImageSize || cropInfo.height < window.maxImageSize) {
            setModal({
                title: 'Invalid Data',
                body: <p>Resize the image to fit in the frame by pressing either
                    <span className="p-1 m-1 bg-white rounded border">+</span> or
                    <span className="p-1 m-1 bg-white rounded border">-</span> buttons</p>,
                footer: [<button key={Math.random()} type="button" className="btn btn-success" onClick={() => setModal(undefined)} >Okey</button>]
            });
            return;
        }

        const formData = new FormData(evt.target);

        if (formData.getAll('game_slots[]').length == 0) {
            setModal({
                title: 'Invalid Data',
                body: <p>Missing game slots</p>,
                footer: [<button key={Math.random()} type="button" className="btn btn-success" onClick={() => setModal(undefined)} >Okey</button>]
            });
            return;
        }

        const url = URL.createObjectURL(formData.get('image'));
        // let imageName;

        setLoading(true);

        new Promise(async (rs) => {
            const url1 = await cropImage(url, window.maxImageSize, window.maxImageSize, cropInfo.x, cropInfo.y, cropInfo.width, cropInfo.height);
            const url2 = await cropImage(url1, 50, 50, 0, 0, 50, 50);
            rs([url1, url2]);
        })

            /* Promise.allSettled([
                cropImage(url, window.maxImageSize, window.maxImageSize, cropInfo.x, cropInfo.y, cropInfo.width, cropInfo.height),
                cropImage(url, 50, 50, cropInfo.x, cropInfo.y, 50, 50)
            ]) */

            .then(urls => Promise.allSettled(urls.map(url1 => fetch(url1).then(r => r.blob()))))
            .then(blobs => {
                formData.delete('image');
                const imageName = `${randomString(16)}.${blobs[0].value.type.split('/').pop()}`;
                const imgNames = [imageName, `small_${imageName}`];
                blobs.forEach((blob, i) => formData.append('images[]', blob.value, imgNames[i]));
                return;
            }).then(() => {
                const headers = (new ApiClient(true)).headers;
                delete headers["Content-Type"];
                return apiClient.create('/image/new', formData, headers).then(response => {
                    setModal({
                        title: !response.error ? 'Upload Successful' : 'Error',
                        body: <p>{response.message}</p>,
                        footer: [<button key={Math.random()} type="button" className="btn btn-success" onClick={() => {
                            if (response.error) setModal(undefined);
                            else navigate('/account/uploads')
                        }} >Okey</button>]
                    });
                    return;
                });
            }).catch(e => {
                setModal({
                    title: 'Error',
                    body: <p>{e.message}</p>,
                    footer: [<button key={Math.random()} type="button" className="btn btn-success" onClick={() => setModal(undefined)} >Okey</button>]
                });
            }).finally(() => setLoading(false));
    }

    function handleCategoryChange(evt) {
        const categoryId = evt.target.value;
        setCategory(categories.find(c => c.parentCategoryID === categoryId));
    }

    return (<>
        <section className="container">
            {modal && <Modal show={true} centered>
                {modal.body && <Modal.Body>
                    {modal.title && <Modal.Title>{modal.title}</Modal.Title>}
                    {modal.body}
                </Modal.Body>}
                {modal.footer && <Modal.Footer>
                    {modal.footer.map((btn, i) => btn)}
                </Modal.Footer>}
            </Modal>}

            <form onSubmit={handleUpload}>
                <div className="d-flex justify-content-between align-items-center mt-4 mb-4">
                    <h3 className="">Upload Image</h3>
                    <div><button className="btn btn-primary" disabled={loading}>Save Game</button></div>
                </div>

                <div className="d-md-flex justify-content-strech">
                    <div className={`mb-4 d-flex flex-column align-items-center`}>
                        <ImageSelector onImageRepositioned={setCropInfo} />

                        <div className="form-group mt-2 w-100">
                            <label htmlFor="category">Category</label>
                            <select name="category" className="form-control" onChange={handleCategoryChange}>
                                <option value={""}>Select a category</option>
                                {categories.map(c => <option key={c.parentCategoryID}
                                    value={c.parentCategoryID}
                                    selected={category && c.parentCategoryID === category.parentCategoryID} >
                                    {c.categoryName}
                                </option>)}
                            </select>
                        </div>
                    </div>

                    <div className={`form-group ${deviceWidth < 480 ? 'mt-2 w-100' : 'ps-4 w-75'}`}>
                        <label htmlFor="grid_location" className=" pb-3">Select slots to place image</label>
                        <div className=" grid-location-container"><GridLocations {...{category}} /></div>
                    </div>
                </div>

                {/* <div className="form-group p-3 fixed-bottom">
                    <button className="btn btn-primary">Save Game</button>
                </div> */}

            </form>
        </section>
    </>);

}