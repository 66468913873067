import { useState } from "react";
import ApiClient from "../../services/api-client.service";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

export default function ForgotPassword() {
  const [userData, setUserData] = useState({
    email: "",
  });
  const [resetPwdData, setResetPwdData] = useState({
    code: "",
    newPassword: "",
    newPasswordConf: "",
  });
  const [validation, setValidation] = useState();
  const [signedup, setSignedup] = useState(false);
  const apiClient = new ApiClient();
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);

  function handleSubmit(evt) {
    evt.preventDefault();
    setValidation(undefined);

    setProcessing(true);
    apiClient
      .create("/auth/request_password_reset", JSON.stringify(userData))
      .then((response) => {
        // navigate('/auth/singin')
        if (!response.error) {
          setStep(1);
          setValidation(undefined);
        } else {
          setValidation({ error: response.error });
        }
      })
      .catch((e) => {
        console.log(JSON.parse(e.message));
        setValidation({ error: JSON.parse(e.message).error.message });
      })
      .finally(() => setProcessing(false));
  }

  function handleField(evt) {
    setUserData({
      ...userData,
      [evt.target.name]: evt.target.value,
    });
  }

  function handlePwdField(evt) {
    setResetPwdData({
      ...resetPwdData,
      [evt.target.name]: evt.target.value,
    });
  }

  function handleResetPasswordSubmit(evt) {
    evt.preventDefault();
    setValidation(undefined);

    setProcessing(true);
    apiClient
      .create("/auth/reset_password", JSON.stringify(resetPwdData))
      .then((response) => {
        if (!response.error) {
          navigate("/auth/signin");
        } else {
          setValidation({ error: response.error });
        }
      })
      .catch((e) => {
        setValidation({ error: JSON.parse(e.message).error.message });
      })
      .finally(() => setProcessing(false));
  }

  return (
    <>
      <Modal show={signedup} centered>
        <Modal.Body>
          <h2>Password reset email sent successful!</h2>
          <p>
            Check your email to verify your account. If the email is not in your
            inbox check under spam emails
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/auth/signin" className="btn btn-success">
            Okey
          </Link>
        </Modal.Footer>
      </Modal>

      <section className="wrapper container h-100 d-flex justify-content-center">
        {/* <div className="w-50 p-5">
                Left
            </div> */}

        {step === 0 ? (
          <form className="p-5 w-50" onSubmit={handleSubmit}>
            <h3>Forgot Password?</h3>
            <Link to="/auth/signin" className="btn text-nowrap text-white ps-0">
              I can remember my password. Sign In
            </Link>
            <p>Enter your email address below to reset your password</p>
            {validation && (
              <div className="p-3 mb-3 mt-3 bg-danger">{validation.error}</div>
            )}

            <div className="form-group mb-3">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={userData.email}
                onChange={handleField}
                className="form-control"
              />
            </div>

            <div className="form-group d-flex justify-conent-between">
              <button
                className="btn btn-primary text-nowrap w-100"
                disabled={processing}
              >
                Request Password Reset
              </button>
            </div>
          </form>
        ) : (
          <form className="p-5 w-50" onSubmit={handleResetPasswordSubmit}>
            <h3>Reset Password?</h3>
            <Link to="/auth/signin" className="btn text-nowrap text-white ps-0">
              I can remember my password. Sign In
            </Link>
            <p>
              Enter the verication code that was sent to your email address(
              {userData.email})
            </p>
            {validation && (
              <div className="p-3 mb-3 mt-3 bg-danger">{validation.error}</div>
            )}

            <div className="form-group mb-3">
              <label>Verification Code</label>
              <input
                type="text"
                name="code"
                value={resetPwdData.code}
                onChange={handlePwdField}
                className="form-control"
              />
            </div>

            <div className="form-group mb-3">
              <label>New Password</label>
              <input
                type="password"
                name="newPassword"
                value={resetPwdData.newPassword}
                onChange={handlePwdField}
                className="form-control"
              />
            </div>

            <div className="form-group mb-3">
              <label>Confirm Password</label>
              <input
                type="password"
                name="newPasswordConf"
                value={resetPwdData.newPasswordConf}
                onChange={handlePwdField}
                className="form-control"
              />
            </div>

            <div className="form-group d-flex justify-conent-between">
              <button
                className="btn btn-primary text-nowrap w-100"
                disabled={processing}
              >
                Reset Password
              </button>
            </div>
          </form>
        )}
      </section>
    </>
  );
}
