import { createContext, useContext, useEffect, useState } from "react";
import UserProvider, { UserContext } from "./user.context";
import Auth from "../models/auth";
import { WorkerContext } from "./worker/worker.context";

export const AuthContext = createContext([Auth.decode()]);

export default function AuthProvider({ children }) {

    const [auth, setAuth] = useState(Auth);
    const [tokens, setTokens] = useState(sessionStorage.getItem('auth'));
    const {worker, workerMessage} = useContext(WorkerContext);
    // const routes 

    useEffect(() => {
        if (tokens) {
            sessionStorage.setItem('auth', typeof tokens === "string" ? tokens : btoa(JSON.stringify(tokens)));
            setAuth(Auth.decode(tokens));
            const { accessToken, user } = Auth.decode(tokens);
            worker.postMessage({ action: 'connect', accessToken, ...user });
        }
        else {
            sessionStorage.removeItem('auth');
            setAuth(undefined);
        }
    }, [tokens]);

    useEffect(() => {
        const sessionStorageUpdates = () => console.log("SS UPdate");
        window.addEventListener("storage", sessionStorageUpdates);
        return () => window.removeEventListener("storage", sessionStorageUpdates);
    }, []);

    return (<AuthContext.Provider value={[auth, setTokens]}>
        <UserProvider>{children}</UserProvider>
    </AuthContext.Provider>);
}