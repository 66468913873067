import { Link } from 'react-router-dom';
import './home.css';
import { useContext } from 'react';
import { DisplayContext } from '../../context/display.context'

export default function Home() {

    const deviceWidth = useContext(DisplayContext);

    return (
        <>
            <section className="wrapper home-hiro">
                <div>
                    <div>
                        <hgroup>
                            <h2 className="text-nowrap">Let the Game Begin!</h2>
                            <h3 className="text-nowrap">Thousands of dollars to be earned!</h3>
                            <h3 className="text-nowrap">Play &bull; Solve &bull; Earn</h3>
                        </hgroup>
                        <Link to='/games' className="btn btn-lg  play-btn">Play Now</Link>
                        &nbsp;&nbsp;
                        <Link to='/explore' className="btn btn-lg btn-outline-light">Explore</Link>
                        
                    </div>
                </div>
                <div>
                    <img alt="" src="/images/m.png" />
                </div>
            </section>

            <section className={`wrapper instruction d-md-flex align-item-strech justify-content-between`}>
                <div className={`flex-fill d-flex flex-column align-items-center justify-content-center ${deviceWidth > 768 ? 'p-5 w-50' : ''}`}>
                    <div className="h-100 p-3 d-flex flex-column align-items-center justify-content-center">
                        <img alt="" className="play" src="images/play.png" />
                    </div>
                </div>
                <div className={`flex-fill d-flex flex-column align-items-center justify-content-center ${deviceWidth > 768 ? 'p-5 w-50' : 'p-4'}`}>
                    <div className={`text-white `}>
                        <h2 className='d-flex align-items-center'>
                            <span className="rounded-circle bg-warning text-black me-3 d-flex align-items-center justify-content-center" style={{width:'50px', height:'50px'}}>1</span>
                            <span>Play</span>
                        </h2>
                        <p className='text-justify'>Explore the categories and pick one. Find a unsolved slot. Play the slot by solving it as required. 
                        </p>
                    </div>
                    <div className="text-white">
                        <h2 className='d-flex align-items-center'>
                            <span className="rounded-circle bg-warning text-black me-3 d-flex align-items-center justify-content-center" style={{width:'50px', height:'50px'}}>2</span>
                            <span>Solve</span>
                        </h2>
                        <p className='text-justify'>
                            Solve a slot to generate the token. Each category has 10,000 tokens of specified values to be solved. 
                        </p>
                    </div>
                    <div className="text-white">
                        <h2 className='d-flex align-items-center'>
                            <span className="rounded-circle bg-warning text-black me-3 d-flex align-items-center justify-content-center" style={{width:'50px', height:'50px'}}>3</span>
                            <span>Earn</span>
                        </h2>
                        <p className='text-justify'>
                            You solve it, you earn it. Gotta be paid for that hard work. Don't you? Enter your paypal email to get paid.                
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}