export default function ContactUs() {
  return (
    <section className="container pt-4">
      <div>
        <h1>Contact Us</h1>
        <p>
          Have questions or concerns? Feel free to reach out to us using the
          contact information below. We appreciate your feedback!
        </p>
        <p>Email:</p>
        <p>info@mail.play2o.com</p>
        <p>support@mail.play2o.com</p>
        {/* Add any additional contact information or a contact form here */}
      </div>
    </section>
  );
}
