import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiClient from "../../services/api-client.service";
import { AuthContext } from "../../context/auth.context";
import { DisplayContext } from "../../context/display.context";
import useApi from "../../hooks/api.hook";

export default function VerifyEmail() {

    const [verificationCode, setVerificationCode] = useState('');
    const [validation, setValidation] = useState();
    const navigate = useNavigate();
    const apiClient = useApi(true);
    const { state } = useLocation();
    const [, setTokens] = useContext(AuthContext);

    const deviceWidth = useContext(DisplayContext);

    function handleSubmit(evt) {

        evt.preventDefault();
        setValidation(undefined);

        apiClient.create('/auth/verify_email', JSON.stringify({verification_code:verificationCode})).then(response => {
            console.log(response)
            if (!response.error) {
                setTokens(response.data);
                navigate(state && state.returnUrl ? state.returnUrl : '/explore')
            }
            else {
                setValidation({ error: response.message });
            }
        })
            .catch(e => {
                setValidation({ error: e.message });
            })

    }

    function handleField(evt) {
        setVerificationCode(evt.target.value);
    }

    function resendCode(){
        apiClient.create('/auth/resend_verification', JSON.stringify({})).then(response=>{
            setValidation(response)
        })
    }

    return (<>
        <section className="wrapper container h-100 d-flex justify-content-center">
            {/* <div className="w-50 p-5">
                Left
            </div> */}
            <form className={`p-5 ${deviceWidth < 480 ? '' : 'w-50'}`} onSubmit={handleSubmit}>
                <h3>Verify Your Email Address</h3>
                <p>Enter the verification code that was sent to your email address</p>
                {validation && <div className={`p-3 mb-3 mt-3 bg-${validation.error ? 'danger':'success'}`}>{validation.error || validation.message}</div>}

                <div className="form-group mb-3">
                    <label>Verification Code</label>
                    <input type="text" name="verification_code" value={verificationCode} onChange={handleField} className="form-control" />
                </div>


                <div className="form-group d-flex justify-conent-between">
                    <button className="btn btn-primary text-nowrap w-100">Sign In</button>
                </div>

                <div className="form-group d-flex justify-conent-between mt-4">
                    <button type="button" className="btn text-nowrap w-100 text-white" onClick={resendCode}>Resend Verification Code</button>
                </div>
            </form>
        </section>
    </>);

}